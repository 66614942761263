import React, { useEffect, useState } from 'react';
import classes from 'styles/classes.module.scss'
import InsertProjectTable from './InsertProjectTable';
import { useApi } from 'hooks/useApi';
import { useParams } from 'react-router-dom';

const InsertedProjects = () => {
	const insertedProjectsId = Number(useParams().insertedProjectsId)
	const { getInsertProjects } = useApi()

	const [projects, setProjects] = useState([])

	useEffect(() => {
		getInsertProjects(`insertProjectsId=${insertedProjectsId}`)
			.then(({ projects }) => {
				setProjects(projects.projects)
			})
	}, [insertedProjectsId, getInsertProjects])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<InsertProjectTable
				projects={projects}
			/>
		</div>
	);
};

export default InsertedProjects;