import { useEffect, useMemo, useState } from 'react'
import { useStoreUtils } from './useStoreUtils'
import { useApi } from './useApi'
import { useParams, useSearchParams } from 'react-router-dom'

export const useProjects = (user, waiting = false) => {
	const [searchParams] = useSearchParams()
	const { userId: userIdFromParams } = useParams()
	const userId = useMemo(() => userIdFromParams ? Number(userIdFromParams) : null, [userIdFromParams])
	useEffect(() => {
		if (userId) {
			searchParams.set('userId',userId)
		}
	}, [userId, searchParams])
	const { getProjects } = useApi()
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils()
	const [projects, setProjects] = useState([])
	const [doublons, setDoublons] = useState([])
	const [audits, setAudits] = useState([])
	const [nbProjects, setNbProjects] = useState(0)
	const doublon = useMemo(() => searchParams.doublon === 'true', [searchParams])
	const typeOfReadable = useMemo(() => Number((Object.fromEntries(searchParams) || {}).type) === 1 ? 'audit' : 'projet', [searchParams])

	useEffect(() => {
		if (waiting) {
			setTableLoadingOn()
			return
		}
		setTableLoadingOn()
		getProjects(user?.id, user?.type, searchParams.toString())
			.then(([projects, count]) => {
				setNbProjects(count)
				setProjects(projects)
				setDoublons(projects)
				setAudits(projects)
			})
			.finally(setTableLoadingOff)
	}, [user, waiting, searchParams, getProjects])


	return {
		audits,
		projects,
		nbProjects,
		setProjects: setProjects,
		setDoublons,
		doublons,
		typeOfReadable,
		doublon
	}
}
