import MyOldTable from 'components/MyTable/MyOldTable';
import React, { useMemo } from 'react';
import classes from '../../styles/classes.module.scss'
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { timeDateFormat } from 'dates';

const HistoriqueProjects = ({
	datas = []
}) => {

	const columns = useMemo(() => [
		{ title: 'Type', type: columnTypes.TEXT, render: ({ type }) => type },
		{ title: 'Modifications', type: columnTypes.TEXT, render: ({ previousProjectObject, projectObject }) => {
			if (!projectObject) return null
			return Object.keys(projectObject).filter(k => k!== 'updatedAt').map(key => {
				const previousValue = (previousProjectObject || {})[key]
				const newValue = projectObject[key]
				if (previousValue === newValue) return null
				return <div key={key} style={{width: 'max-content'}}>
					{key}: {previousValue} {'=>'} {newValue}<br/>
				</div>
			})
		} },
		{ title: 'Date de modification', type: columnTypes.DATE, render: ({ updatedAt }) => timeDateFormat(updatedAt) },

	].filter(e => !!e), [])
	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<MyOldTable
				counter={true}
				columns={columns}
				data={datas}
				title={'Historique des modifications'}
				options={{
					paging: true,
					pageSize: 50
				}}
			/>
		</div>
	);
};

export default HistoriqueProjects;