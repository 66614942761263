import classes from '../../styles/classes.module.scss'
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { useMemo } from "react";
import { timeDateFormat } from "dates";
import MyOldTable from "components/MyTable/MyOldTable";
import cn from "classnames";
import statusStyle from "./Status.module.scss"

const InsertProjectTable = ({
	projects,
	title = 'Insertion de projets',
	isAudit = false
}) => {
	const columns = useMemo(() => [
		{ field: 'statusInsert', title: 'Status', render: ({ statusInsert }) => {
			if (statusInsert == 0) {
				return (
					<div className={cn(statusStyle.container)}>
						<div className={cn(statusStyle.circle)} style={{ backgroundColor: 'green' }}/>
						<p>Ajouté</p>
					</div>
				)
			} else if (statusInsert == 1) {
				return (
					<div className={cn(statusStyle.container)}>
						<div className={cn(statusStyle.circle)} style={{ backgroundColor: 'yellow' }}/>
						<p>doublon</p>
					</div>
				)
			} else {
				return (
					<div className={cn(statusStyle.container)}>
						<div className={cn(statusStyle.circle)} style={{ backgroundColor: 'red' }}/>
						<p>supprimé</p>
					</div>
				)
			}
		}},
		{ field: 'ticketKey', title: 'Clé ticket', type: columnTypes.TEXT },
		{ field: 'auditType', title: 'Type d\'audit', type: columnTypes.TEXT },
		{ field: 'cp', title: 'Code postal', render: ({ cp }) => cp },
		{ field: 'name', title: 'Nom du client', type: columnTypes.TEXT, render: ({ name }) => <div className='highlight'> {name} </div> },
		!!isAudit && { field: 'auditType', title: 'Type d\'audit', type: columnTypes.TEXT, render: ({ auditType }) => auditType },
		{ field: 'createdDate', title: 'Date de création', type: columnTypes.DATE, render: ({ createdDate }) => timeDateFormat(createdDate) },
		{ field: 'createdAt', title: 'Date d\'importation', type: columnTypes.DATE, render: ({ createdAt }) => timeDateFormat(createdAt) },
		{ field: 'auditDate', title: 'Date d\'audit', type: columnTypes.DATE, render: ({ auditDate }) => timeDateFormat(auditDate) },
		{ field: 'auditorDefault', title: 'Auditeur', type: columnTypes.TEXT, render: ({ auditorDefault }) => auditorDefault },
		{ field: 'regieDefault', title: 'Régie', type: columnTypes.TEXT, render: ({ regieDefault }) => regieDefault },
		{ field: 'engineerDefault', title: 'Ingénieur', type: columnTypes.TEXT, render: ({ engineerDefault }) => engineerDefault },
	].filter(e => !!e), [isAudit])
	return (
		<>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyOldTable
					counter={true}
					nbTotalRow={projects.length}
					columns={columns}
					data={projects}
					title={title}
					options={{
						paging: true,
						pageSize: 50
					}}
				/>
			</div>
		</>
	)
}

export default InsertProjectTable;