import React, { useMemo } from 'react';
import { DatePicker } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './PeriodSelection.module.scss'
import classNames from 'classnames';
const { RangePicker } = DatePicker;

const PeriodSelection = ({
	labelText = null
}) => {
	const { search } = useLocation()
	const searchParams = useMemo(() => new URLSearchParams(search), [search]) 
	const navigate = useNavigate()
	const startQueryName = labelText ? `${labelText.toLowerCase()}StartDate` : 'startDate'
	const endQueryName = labelText ? `${labelText.toLowerCase()}EndDate` : 'endDate'

	const formatDateToLocalDateString = (date) => {
		const formatter = new Intl.DateTimeFormat('fr-FR', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'Europe/Paris' // Ajustez le timeZone selon le besoin
		});

		// Décompose la date formatée pour la réorganiser en format YYYY-MM-DD
		const [day, month, year] = formatter.format(date).split('/');
		return `${year}-${month}-${day}`;
	};



	const changeDates = (datesArr) => {
		let startDate = new Date('01/01/1000')
		let endDate = new Date('12/30/20000')
		if (datesArr && datesArr.length === 2) {
			startDate = datesArr[0].startOf('day').toDate()
			endDate = datesArr[1].endOf('day').toDate()
			const startDateString = formatDateToLocalDateString(startDate);
			const endDateString = formatDateToLocalDateString(endDate);
			searchParams.set(startQueryName, startDateString);
			searchParams.set(endQueryName, endDateString);
		} else {
			searchParams.delete(startQueryName)
			searchParams.delete(endQueryName)
		}
		navigate(`?${searchParams.toString()}`, { replace: true })
		return true
	}

	return (
		<div className={classNames(classes.container)}>
			{labelText && <p className={classNames(classes.label)}>{labelText}</p>}
			<RangePicker
				style={{ width: 'max-content' }}
				className={classNames(classes.blue)}
				onChange={changeDates}
			/>
		</div>
	);
};

export default PeriodSelection;