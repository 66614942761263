import { useProjects } from 'hooks/useProjects';
import React, { useCallback, useMemo, useState } from 'react';
import ProjectsTable from './ProjectsTable';
import classes from '../../styles/classes.module.scss'
import Button from 'components/Button/Button';
import { isEmpty, upperFirst } from 'lodash';
import toast from 'components/toast/toast';
import { useApi } from 'hooks/useApi';
import classNames from 'classnames';

const ApiTableProjects = () => {
	const { deleteProjects, updateProjects } = useApi()
	const [loading, setLoading] = useState(false)
	const { setProjects, nbProjects, projects, typeOfReadable, doublon } = useProjects()
	const allAreChecked = useMemo(() => projects.every(project => !!project.checked), [projects])

	const projectsChecked = useMemo(() => projects
		.filter(e => !!e.checked)
		.reduce((prev, { id, cp, name, ticketKey }) => ({
			...prev,
			[id]: ticketKey + '	' + cp + '	' + name
		}), {})
		, [projects])
	const onChecked = useCallback(({ id, cp, name }) => {
		setProjects(projects => {
			const index = projects.findIndex(project => project.id === id)
			projects[index].checked = !!!projects[index].checked
			return [...projects]
		})
	}, [setProjects])

	const copy = useCallback(async () => {
		setLoading(true)
		try {
			await navigator.clipboard.writeText(Object.values(projectsChecked).join('\n'))
			await updateProjects(Object.keys(projectsChecked).map(Number), { status: 3 })
			const keys = Object.keys(projectsChecked).map(Number)
			setProjects(projects => projects.map(project => keys.includes(Number(project.id)) ? {
				...project,
				status: 3,
				statusObj: {
					id: 3,
					status: 'Envoie en facturation',
					order: 2,
					color: 'yellow'
				}
			} : project))
			toast.success('Copié dans le presse-papier')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [projectsChecked, setProjects, updateProjects])

	const remove = useCallback(async () => {
		setLoading(true)
		try {
			const ids = Object.keys(projectsChecked).map(Number)
			await deleteProjects(ids)
			setProjects(projects => projects.filter(project => !ids.includes(Number(project.id))))
			toast.success(ids.length + ' lignes supprimées')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [deleteProjects, projectsChecked, setProjects])

	const checkAll = useCallback(() => {
		setProjects(projects => projects.map(project => ({ ...project, checked: true })))
	}, [setProjects])
	const unCheckAll = useCallback(() => {
		setProjects(projects => projects.map(project => ({ ...project, checked: false })))
	}, [setProjects])
	return (
		<div className={classNames(classes.relative, classes.flex, classes.columnDirection, classes.alignItemsLeft)}>
			<h1 className={classes.taLeft}>Mes {doublon ? 'Doublon' : ''}{upperFirst(typeOfReadable)}s</h1>
			<ProjectsTable
				projects={projects}
				nbProjects={nbProjects}
				setProjects={setProjects}
				checkBoxFunc={onChecked}
			/>
			<div className={classes.leftBottom}>
				{!isEmpty(projectsChecked) && <> <Button
					loading={loading}
					onClick={copy}
				>
					Copier les {Object.keys(projectsChecked).length} {typeOfReadable}s
				</Button>
					<Button
						type='danger'
						loading={loading}
						onClick={remove}
					>
						Suprimer les {Object.keys(projectsChecked).length} {typeOfReadable}s
					</Button>
				</>}
				{<Button
					type='black'
					// loading={loading}
					onClick={allAreChecked ? unCheckAll : checkAll}
				>
					{allAreChecked ? "Tout déselectionner" : "Tout selectionner"}
				</Button>}
			</div>
		</div>
	);
};

export default ApiTableProjects;
